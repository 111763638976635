<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="top-bar mb-2">
        <h3 class="primary--text text-lg-h5 mr-2">Cab Approval Requests</h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="cabApprovals"
          :sort-desc="[false, true]"
          :search="search"
          multi-sort
          class="elevation-1"
          ref="cabrequest_list"
          id="scollStyle"
        >
          <template v-slot:item.recordToApprove="{ item }">
            {{ item.recordToApprove }}
          </template>
          <template v-slot:item.dueDate="{ item }">
            <span>{{ getDate(item.dueDate) }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="getStatusColor(item.status)">{{
              item.status
            }}</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="primary lighten-4 primary--text text--darken-4"
              elevation="0"
              @click="viewApproval(item)"
            >
              <v-icon class="mx-2">mdi-eye</v-icon>
              View
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { helper } from "@kinetics254/cassandra-base/utilities";
export default {
  name: "LitteleCabApprovalRequests",
  mixins: [],
  components: {},
  data: function () {
    return {
      search: "",
      selectedGroup: "Cab Request Header",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getApprovalApplications();
    });
  },
  mounted() {},
  created() {},
  watch: {},
  computed: {
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "entryNo",
        },
        {
          text: "Request To Approve",
          align: "start",
          sortable: true,
          value: "approveForType",
        },
        {
          text: "Request Number",
          align: "start",
          sortable: true,
          value: "documentNo",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "dueDate",
          align: "start",
          sortable: true,
          value: "dueDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    cabApprovals() {
      const approvals = this.$store.getters["littlecab/littleCabGetters"](
        "approvalApplications"
      );
      return approvals.data
        ? approvals.data
            .sort((a, b) => {
              return new Date(b.dueDate) - new Date(a.dueDate);
            })
            .filter((el) =>
              this.selectedGroup.length > 0
                ? el.approveForType === this.selectedGroup
                : true
            )
        : [];
    },
    approvalGroups() {
      const approvals = this.$store.getters["littlecab/littleCabGetters"](
        "approvalApplications"
      );
      return approvals.groups
        ? approvals.groups.map((el) => {
            return {
              text: el.approveForType,
              value: approvals.data.filter(
                (item) => item.approveForType === this.selectedGroup
              ).length,
            };
          })
        : [];
    },
  },
  methods: {
    getDate(date) {
      return helper.getDateFormat(date);
    },
    getStatusColor(status) {
      return helper.getColor(status).color;
    },
    getApprovalApplications: function () {
      this.$store.dispatch("littlecab/getCabApprovalApplications", {
        approverID: this.userData.employee,
        status: "Open",
      });
    },
    viewApproval(item) {
      this.$router.push({
        name: "LittleCabApprovalCard",
        params: {
          documentNo: btoa(item.documentNo),
          approverID: btoa(item.approverID),
        },
      });
    },
  },
};
</script>

<style scoped></style>
