<template>
  <v-card class="mx-auto approver--card" flat>
    <v-card-title>
      <v-btn text color="secondary" class="mx-2" @click="$router.back()">
        <v-icon class="mx-1">arrow_back</v-icon>
        back
      </v-btn>
      Cab Approval Entry {{ approval.entryNo }}
      <v-spacer />
      <v-btn class="green white--text mx-2" @click="approve">
        <v-icon class="mx-2">thumb_up</v-icon>
        Approve
      </v-btn>
      <v-btn class="red white--text" @click="rejectDialog = true">
        <v-icon class="mx-2">thumb_down</v-icon>
        Reject
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-toolbar
        color="primary"
        class="white--text text-capitalize text-body-1"
        dense
      >
        Approval Document - {{ approval.documentNo }}
        <v-spacer />
      </v-toolbar>
      <div>
        <v-list dense>
          <template>
            <!-- <v-divider></v-divider> -->
            <v-list-item v-for="(data, key) in requestDetails" :key="key">
              <v-list-item-content class="d-flex flex-row">
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-list-item-title class="text-capitalize">
                      <!-- {{ key ==='postingDate'?"Request date": key }} -->
                      {{ formatKey(key) }}
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-list-item-subtitle>
                      {{ computeDisplayData(key, data) }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card-text>
    <!-- <ApprovalDocuments :documents="documents" /> -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card class="mx-auto" width="500">
        <v-card-text>
          <div class="text-body-1">
            Sorry, you cannot approve this cab request. Please contact the
            sender/admin for more information.
          </div>
        </v-card-text>
        <v-card-actions>
          <div>Redirecting in {{ countDown }} seconds...</div>
          <v-spacer />
          <v-btn
            color="primary"
            small
            class="text-body-1 text-capitalize"
            link
            to="/"
          >
            <v-icon class="mx-2">dashboard</v-icon>
            Go to Dashboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- reject reason-->
    <v-dialog v-model="rejectDialog" persistent max-width="500">
      <v-card class="mx-auto" width="500">
        <v-card-title>
          <div class="text-body-1">Add reject reason</div>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="rejectReason"
            label="Reject Reason"
            outlined
            rows="3"
            maxlength="250"
            dense
            clearable
            hint="250 characters max"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            class="text-capitalize"
            small
            @click="rejectDialog = false"
          >
            <v-icon>mdi-close</v-icon>
            close
          </v-btn>
          <v-spacer />
          <v-btn class="red white--text" @click="reject">
            <v-icon class="mx-2">thumb_down</v-icon>
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import pdf from "vue-pdf";
// import ApprovalDocuments from "./ApprovalDocuments.vue";
import FileMixin from "@/packages/cassandra-base/mixins/FileMixin";
export default {
  name: "LittleCabApprovalCard",
  mixins: [FileMixin],
  components: {
    // pdf,
    // ApprovalDocuments,
  },
  data() {
    return {
      dialog: false,
      countDown: 10,
      numPages: undefined,
      rejectDialog: false,
      rejectReason: "",
    };
  },
  mounted() {
    if (this.userData.employee.trim() !== atob(this.$route.params.approverID)) {
      this.dialog = true;
      this.calcCountDown();
    }
    Event.$on("approvalentries", () => {
      this.$router.push({ name: "cabApplications" });
    });
    //get the cab request (specific)
    this.$store.dispatch(
      "littlecab/getCabRequestsDetails",
      this.$route.params.documentNo
    );
  },
  computed: {
    approval() {
      const approvals = this.$store.getters["littlecab/littleCabGetters"](
        "approvalApplications"
      );
      return (
        approvals.data?.filter((el) => {
          return (
            el.documentNo === atob(this.$route.params.documentNo) &&
            el.approverID === atob(this.$route.params.approverID) &&
            el.status === "Open"
          );
        })[0] || {}
      );
    },
    getDocument() {
      return this.approval.pdfAttachment
        ? process.env.VUE_APP_FILE_API + this.approval.pdfAttachment
        : "";
    },
    documents() {
      return this.approval.documentAttachments
        ? this.approval.documentAttachments.map((el) => {
            el.base64Attachment =
              process.env.VUE_APP_FILE_API + el.base64Attachment;
            return {
              ...el,
            };
          })
        : [];
    },
    userData() {
      return AuthService.user;
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    requestDetails() {
      const {
        estimateAmount,
        description,
        requesterName,
        fromLocation,
        toLocation,
        postingDate,
        returnTrip,
      } =
        this.$store.getters["littlecab/littleCabGetters"]("cabRequestsDetails");
      return {
        estimateAmount,
        description,
        requesterName,
        fromLocation,
        toLocation,
        postingDate,
        returnTrip,
      };
    },
  },
  methods: {
    approve() {
      this.$confirm.show({
        title: "Approve",
        text: "Are you sure you want to approve this cab request?",
        onConfirm: () => {
          this.$store.dispatch("littlecab/approve", {
            ...this.approval,
          });
        },
      });
    },

    formatKey(key) {
      if (key === "postingDate") {
        return "Request Date";
      }
      // Use regular expression to insert space before each uppercase letter
      return key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
    },
    reject() {
      this.$confirm.show({
        title: "Reject",
        text: "Are you sure you want to reject this cab request?",
        onConfirm: () => {
          this.$store.dispatch("littlecab/reject", {
            ...this.approval,
            reason: this.rejectReason,
          });
        },
      });
    },
    calcCountDown() {
      const interval = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(interval);
          this.$router.push("/");
        }
      }, 1000);
    },
    getPages: function (document) {
      if (document.length > 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
    computeDisplayData(key, data) {
      switch (key) {
        case "returnTrip":
          return data === true ? "Yes" : "No";
        case "postingDate":
          return data;
        default:
          return data;
      }
    },
  },
};
</script>
<style>
.approver--card > .v-card__title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.approver--card > .v-divider {
  position: sticky;
  top: 70px;
  z-index: 1;
}
</style>
