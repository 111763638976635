<template>
  <v-container fluid>
    <v-app-bar
      class="leaveBar mb-2"
      dense
      style="background-color: #f0f3f4"
      :style="{ border: '0' }"
    >
      <div class="text-lg-h5" style="font-weight: 300">Cab Requests</div>
      <v-spacer></v-spacer>
      <v-breadcrumbs
        customDivider
        divider="/"
        large
        :items="path"
      ></v-breadcrumbs>
    </v-app-bar>
    <!--BEGIN summary stats cards-->
    <!--BEGIN summary stats cards-->
    <v-row class="statistic-cards mt-n4">
      <!--BEGIN the pending balance card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="status = 'All'"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">ALL</h5>
                    <h1>{{ total }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary">mdi-cash</v-icon></v-avatar
                  >
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the pending balance card-->
      <!-- Begin the open Request card -->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="status = 'Open'"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">OPEN</h5>
                    <h1>{{ countOpenRequests }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary"> mdi-timelapse</v-icon>
                  </v-avatar>
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the open requests card-->
      <!--BEGIN the pending amount card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="status = 'Pending Approval'"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">PENDING</h5>
                    <h1>{{ countPendingRequests }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary"> mdi-timelapse</v-icon>
                  </v-avatar>
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the pending requests card-->
      <!--BEGIN the approved requests card-->
      <v-col lg="3" cols="sm" class="pb-2">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            @click="(status = 'Open'), (status = 'Approved')"
            :elevation="hover ? 5 : 0"
            :class="{ 'statistic-cards-hover': hover }"
            style="border-left: var(--v-primary-base) solid"
          >
            <v-row class="no-gutters">
              <div class="col-9">
                <v-row>
                  <div class="col-auto">
                    <div class="primary fill-height">&nbsp;</div>
                  </div>
                  <div class="col pa-3 py-4 primary--text">
                    <h5 class="text-truncate text-uppercase">ACCOUNTED</h5>
                    <h1>{{ countAccountedRequest }}</h1>
                  </div>
                </v-row>
              </div>
              <div class="col-3">
                <v-card-actions>
                  <v-avatar color="primary lighten-4" size="50"
                    ><v-icon color="primary"> mdi-star-circle</v-icon>
                  </v-avatar>
                </v-card-actions>
              </div>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <!--END the approved requests card-->
    </v-row>
    <!--END summary stats-->
    <v-row>
      <v-col cols="12">
        <v-card tile class="mt-3" elevation="0">
          <v-card flat>
            <!--BEGIN top bar-->
            <v-card-title class="top-bar mb-2">
              <h3 class="primary--text text-lg-h5 mr-2">Cab Requests</h3>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
              <v-menu
                v-model="filterDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    prepend-inner-icon="filter_alt"
                    color="primary"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    single-line
                    hide-details
                    label="Filter by date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterDate"
                  @input="
                    (filterDatePicker = false),
                      (startDate = filterDate[0]),
                      (endDate = filterDate[1])
                  "
                  @click="(status = 'Open'), (status = 'Issued')"
                  range
                ></v-date-picker>
              </v-menu>
              <v-spacer />
              <v-btn :to="{ name: 'LittleCabCard' }" color="primary">
                <v-icon left>mdi-plus</v-icon>
                New Request
              </v-btn>
            </v-card-title>
            <!--END top bar-->
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="cabRequests"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items-per-page="5"
                fixed-header
                class="table-font table-striped"
              >
                <!--BEGIN date slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.postingDate="{ item }">
                  <span class="table-font">{{
                    item.postingDate | formatDate
                  }}</span>
                </template>
                <!--END date slot-->
                <!--BEGIN description slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.travelDate="{ item }">
                  <span class="table-font">{{
                    item.travelDate | formatDate
                  }}</span>
                </template>
                <!--END date slot-->
                <!--BEGIN amount slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.distanceTraveled="{ item }">
                  <span class="table-font">
                    {{ item.distanceTraveled | number }}KM</span
                  >
                </template>
                <template v-slot:item.estimateAmount="{ item }">
                  <span class="table-font">
                    {{ item.estimateAmount | currencyFormat }}</span
                  >
                </template>
                <template v-slot:item.amountCharged="{ item }">
                  <span class="table-font">
                    {{ item.amountCharged | currencyFormat }}</span
                  >
                </template>
                <!--END amount slot-->
                <!--BEGIN status slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.status="{ item }">
                  <v-icon
                    small
                    left
                    :color="
                      item.status === 'Approved' && item.status === 'Open'
                        ? getColor('Review').color
                        : getColor(item.status).color
                    "
                    >mdi-radiobox-marked</v-icon
                  >
                  <span small lowercase text-color="white">
                    <span
                      v-if="item.status === 'Open'"
                      class="text-capitalize"
                      >{{ item.status }}</span
                    >
                    <span
                      v-else-if="item.status === 'Released'"
                      class="text-lowercase"
                      >approved</span
                    >
                    <span v-else>{{ item.status | capitalize }}</span>
                  </span>
                </template>
                <!--END status slot-->
                <!---BEGIN actions slot-->
                <!--eslint-disable-next-line -->
                <template v-slot:item.action="{ item }">
                  <div class="d-flex justify-start">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          label
                          small
                          :to="{
                            name: 'LittleCabCard',
                            params: { code: item.no },
                          }"
                          class="mx-1 text--white text-capitalize"
                        >
                          <v-icon small left color="white">mdi-eye</v-icon>
                          view
                        </v-chip>
                      </template>
                      <span>view Details</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          label
                          small
                          v-show="canRequestApproval(item)"
                          @click="requestApproval(item.no)"
                          class="mx-1 text--white text-capitalize"
                        >
                          <v-icon small left color="white">mdi-check</v-icon>
                          Request Approval
                        </v-btn>
                      </template>
                      <span>Request Approval</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-show="item.status === 'Pending Approval'"
                          class="mx-1"
                          color="orange lighten-4"
                          label
                          ripple
                          small
                          v-bind="attrs"
                          @click="cancelCabApprovalRequest(item)"
                          v-on="on"
                        >
                          <v-icon color="orange darken-4" small
                            >mdi-timer-cancel-outline</v-icon
                          >
                        </v-chip>
                      </template>
                      <span>cancel cab request</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-show="item.status === 'Open'"
                          class="mx-1"
                          color="red lighten-4"
                          label
                          small
                          v-bind="attrs"
                          @click="deleteCabRequest(item)"
                          v-on="on"
                        >
                          <v-icon color="red darken-4" small>mdi-delete</v-icon>
                        </v-chip>
                      </template>
                      <span>Remove this request</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <!--BEGIN account button-->
                        <v-btn
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          small
                          @click="accountForCabRequest(item.no)"
                          class="mx-1 white--text text-capitalize"
                          elevation="0"
                          v-show="item.status === 'Released'"
                        >
                          <v-icon small class="mr-2">mdi-cash</v-icon> account
                        </v-btn>
                      </template>
                      <span>account</span>
                    </v-tooltip>
                  </div>
                </template>
                <!--END actions slot-->
              </v-data-table>
            </v-card-text>
            <!-- END the table of requests-->
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <little-cab-account-request-dialog
      :dialog="dialog"
      :request-no="selectedRequestNo"
      v-on:close-dialog="closeDialog"
    />
  </v-container>
</template>
<script>
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import helper from "@kinetics254/cassandra-base/utilities/helpers/helper";
import LittleCabAccountRequestDialog from "@/packages/cassandra-littlecab/components/LittleCabAccountRequestDialog.vue";
import littleCabMixin from "../mixins/littleCabMixin.js";

export default {
  name: "LittleCabApplicationList",
  components: { LittleCabAccountRequestDialog },
  mixins: [littleCabMixin],
  data: function () {
    return {
      sortBy: "no",
      sortDesc: true,
      loaded: false,
      search: "",
      filterDate: [],
      filterDatePicker: "",
      tab: null,
      status: "All",
      headers: [
        {
          text: "#No",
          align: "center",
          sortable: true,
          value: "no",
        },
        { text: "Request Date", align: "", value: "postingDate" },
        { text: "Requested By", align: "center", value: "requesterName" },
        { text: "Travel Date", align: "", value: "travelDate" },
        { text: "From", align: "", value: "fromLocation" },
        { text: "To", align: "", value: "toLocation" },
        { text: "Estimated Amount", align: "center", value: "estimateAmount" },
        { text: "Amount Charged", align: "center", value: "amountCharged" },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        { text: "Action", align: "start", value: "action" },
      ],
      selectedComments: null,
      dialog: false,
      selectedRequestNo: null,
      path: this.$router.currentRoute.meta.breadcrumb,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("littlecab/getCabRequests", v.user.employee);
    });
  },
  mounted() {
    Event.$on("re-sync", () => {
      this.$store.dispatch("littlecab/getCabRequests", this.user.employee);
    });
  },

  computed: {
    cabRequests() {
      if (this.filterDate.length !== 0) {
        return this.status === "All"
          ? this.$store.getters["littlecab/littleCabGetters"](
              "cabRequests"
            ).filter(
              (a) =>
                new Date(a.postingDate) >= this.startDate &&
                new Date(a.postingDate) <= this.endDate
            )
          : this.$store.getters["littlecab/littleCabGetters"](
              "cabRequests"
            ).filter(
              (a) =>
                new Date(a.postingDate) >= this.startDate &&
                new Date(a.postingDate) <= this.endDate &&
                a.status === this.status
            );
      }
      return this.status === "All"
        ? this.$store.getters["littlecab/littleCabGetters"]("cabRequests")
        : this.$store.getters["littlecab/littleCabGetters"](
            "cabRequests"
          ).filter((el) => el.status === this.status);
    },

    user() {
      return AuthService.user;
    },

    dateRangeText() {
      return this.filterDate.join(" To ");
    },
    total() {
      let total = 0;
      this.cabRequests.forEach((i) => {
        total += Number(i.amountCharged);
      });

      return helper.shortNumberPipe(total);
    },
    /**
     * total issued/APPROVED REQUESTS
     * @returns {number}
     */
    countAccountedRequest: function () {
      return helper.countByStatus(this.cabRequests, "amountCharged", "null");
    },
    /**
     * Pending requests counter
     */
    countPendingRequests: function () {
      return helper.countByStatus(
        this.cabRequests,
        "status",
        "Pending Approval"
      );
    },
    /**
     * total under review requests
     * @returns {number}
     */
    // countUnderReviewRequests: function () {
    //   return helper.countByStatus(
    //     this.cabRequests,
    //     "Approved",
    //     "status",
    //     "Open"
    //   );
    // },

    countOpenRequests: function () {
      return helper.countByStatus(this.cabRequests, "status", "Open");
    },
  },
  methods: {
    readMore(text, length) {
      return helper.addReadMore(text, length);
    },
    getColor(val) {
      return helper.getColor(val);
    },

    requestApproval(docNo) {
      this.$store.dispatch("littlecab/requestApproval", { documentNo: docNo });
    },

    accountForCabRequest: function (code) {
      this.dialog = true;
      this.selectedRequestNo = code;
    },

    closeDialog: function (val) {
      this.dialog = val;
    },
    canRequestApproval(req) {
      return !["Released", "Accounted", "Pending Approval"].includes(
        req.status
      );
    },
  },
  watch: {
    filterDate() {
      if (this.filterDate.length === 1) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[0]);
      }
      if (this.filterDate.length === 2) {
        this.startDate = new Date(this.filterDate[0]);
        this.endDate = new Date(this.filterDate[1]);
      }
    },
  },
};
</script>
<style scoped>
@import url("../styles/style.css");

* {
  font-family: Poppins, sans-serif !important;
}

.top-bar {
  background-color: #e8eaf6 !important;
  cursor: pointer;
  border-bottom: 1px solid #e8eaf6;
}

.request-header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 175.6%;
  text-align: justify;
  color: var(--v-primary-base);
  text-transform: uppercase;
}

.statistic-cards-hover {
  box-shadow: 0 40px 60px -20px rgba(12, 5, 62, 0.15);
  z-index: 100;
  cursor: pointer;
  font-size: 14px;
  background-color: #e8eaf6;
}
</style>
